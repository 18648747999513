import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { Map } from "immutable"
import classNames from "classnames"
import Markdown from "highline/components/markdown"
import { CloseIcon } from "highline/components/icons"

import styles from "highline/styles/components/application/tippy_top.module.css"

const TippyTop = ({
  onTippyTopHeightChange,
  backgroundColor,
  description,
  textColor,
  title,
  className,
  isOpen,
  onToggleDetails,
  showDetails,
  onClickDismiss,
}) => {
  const tippyTopRef = useRef(null)

  useEffect(() => {
    onTippyTopHeightChange(tippyTopRef?.current?.clientHeight || 0)
  }, [onTippyTopHeightChange, tippyTopRef?.current?.clientHeight])

  if (!title || !isOpen) {
    return null
  }

  const isColorHexadecimal = (color) => {
    return /^#([a-zA-Z]|[0-9]){6}$/.test(color)
  }

  const backgroundInLineStyle = isColorHexadecimal(backgroundColor)
  const textColorInLineStyle = isColorHexadecimal(textColor)

  return (
    <div
      className={classNames(
        "component",
        "tippy-top-component",
        styles.component,
        styles[backgroundColor],
        styles[textColor],
        className,
        backgroundInLineStyle ? undefined : styles[backgroundColor],
        textColorInLineStyle ? undefined : styles[textColor]
      )}
      style={{
        backgroundColor: backgroundInLineStyle ? backgroundColor : "",
        color: textColorInLineStyle ? textColor : "",
      }}
      ref={tippyTopRef}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          <Markdown align="center" source={title} />
        </div>
        {description && (
          <div>
            {!showDetails && (
              <button
                aria-label="View details"
                className={classNames(
                  styles.showDetails,
                  backgroundInLineStyle ? undefined : styles[backgroundColor],
                  textColorInLineStyle ? undefined : styles[textColor]
                )}
                style={{
                  backgroundColor: backgroundInLineStyle ? backgroundColor : "",
                  color: textColorInLineStyle ? textColor : "",
                }}
                onClick={onToggleDetails}
              >
                Details
              </button>
            )}
            {showDetails && (
              <div>
                <div id="tippy-top-description" className={styles.description}>
                  {description}
                </div>
                <button
                  aria-label="Hide details"
                  className={classNames(
                    styles.hideDetails,
                    backgroundInLineStyle ? undefined : styles[backgroundColor],
                    textColorInLineStyle ? undefined : styles[textColor]
                  )}
                  style={{
                    backgroundColor: backgroundInLineStyle ? backgroundColor : "",
                    color: textColorInLineStyle ? textColor : "",
                  }}
                  onClick={onToggleDetails}
                >
                  Hide Details
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <button
        aria-label="Dismiss banner"
        className={classNames(
          styles.dismissBtn,
          backgroundInLineStyle ? undefined : styles[backgroundColor],
          textColorInLineStyle ? undefined : styles[textColor]
        )}
        style={{
          backgroundColor: backgroundInLineStyle ? backgroundColor : "",
          color: textColorInLineStyle ? textColor : "",
        }}
        onClick={onClickDismiss}
      >
        <CloseIcon />
      </button>
    </div>
  )
}

TippyTop.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  content: ImmutablePropTypes.map,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onClickDismiss: PropTypes.func,
  onTippyTopHeightChange: PropTypes.func,
  onToggleDetails: PropTypes.func,
  path: PropTypes.string,
  showDetails: PropTypes.bool,
  textColor: PropTypes.string,
  title: PropTypes.string,
}

TippyTop.defaultProps = {
  content: Map({}),
  isOpen: false,
  backgroundColor: "grey",
  textColor: "light",
  onClickDismiss: () => {},
  onToggleDetails: () => {},
  showDetails: false,
  onTippyTopHeightChange: () => {},
}

export default TippyTop
